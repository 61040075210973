.infoblock {
  display: flex;
  width: 480px;
  min-height: 320px;
  height: fit-content;
  max-height: 640px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #00000033;  
  text-shadow: var(--bg-color) 0px 0px 5px, var(--bg-color) 0px 0px 3px, var(--bg-color) 0px 0px 1px;
  
  transition: all .25s ease-in-out;

  span {
    color: var(--highlight)
  }

  &:hover {
    transform: translate(-2px, -2px);
    box-shadow: 4px 4px 5px rgb(0 0 0 / 20%);
    transition: all 0.25s ease-in-out;
    a {
      span {
        color: var(--highlight-hover);
        text-shadow: 0 0 10px var(--highlight-alpha);
      }

      &:active {
        span {
          color: var(--highlight-active)
        }
      }
    }
  }
  
  h4 {
    color: var(--color-tarnished);
  }

  a {
    width: 100%;
    text-decoration: none;
    color: var(--color);
    border-radius: 10px;
    transition: all .25s ease-in-out;

    &:hover {
      cursor: pointer;
      color: var(--color);
      backdrop-filter: var(--blur10);
      transition: all .25s ease-in-out;
    }
  }

  div {
    a {
      color: var(--highlight);

      &:hover {
        cursor: pointer;
        color: var(--highlight-hover);
      }

      &:active {
        color: var(--highlight-active);
      }
    }
  }

  p {
    font-size: 32px;
  }
}

@media only screen and (max-width: 1080px) {
  .infoblock {
    width: 360px;
    min-height: 280px;
    height: fit-content;
    max-height: 560px;

    p {
      font-size: 28px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .infoblock {
    width: 100%;
    min-height: 200px;
    height: fit-content;
    max-height: 560px;

    h4 {
      margin-block-start: 0;
      margin-block-end: 5px;
    }

    p {
      margin-block: 0;
      font-size: 24px;
    }
  }
}

.content {
  padding: 10px; // margin???
  text-transform: uppercase;
}