@import '../../styles/common.scss';

$breakpoint_tablet: 1080px;
$breakpoint_mobile: 600px;

.modal_wrapper {
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: var(--bg-color-alpha);
  backdrop-filter: var(--blur25);
  inset: 0;
  padding-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  min-width: 800px;
  width: 100%;
  max-width: 1150px;
  min-height: 480px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #0003;
  background: var(--bg-color);
  font-size: 20px;

  &_header {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

@media screen and (max-width: $breakpoint_tablet) {
  .modal {
    min-width: unset;
    flex-direction: column;
    font-size: 18px;
    min-height: unset;
  }
}

@media screen and (max-width: $breakpoint_mobile) {
  .modal {
    min-width: unset;
    flex-direction: row;
    font-size: 14px;
    min-height: unset;
  }
}

.form_container {
  margin: 25px;
}

.image_container {
  max-width: 500px;
  display: flex;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media screen and (max-width: $breakpoint_tablet) {
  .image_container{
    min-height: 240px;
    max-width: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media screen and (max-width: $breakpoint_mobile) {
  .image_container{
    display: none;
  }
}

.info_container {
  display: flex;
  align-items: flex-end;
  margin: 10px;
}

.info {
  height: 24px;
  background-color: var(--bg-color-alpha);
  border-radius: 10px;
  padding: 5px;
  margin: 2px;
  white-space: nowrap;
}

.image_proxy_flag {
  height: 10px;
  border: 2px solid var(--color);
  border-radius: 25%;
  margin-left: 2px;
  margin-bottom: -2px;
}

// rewrite/remove? its for server.mods details block
.mods_details_content {
  //font-size: 10px;
  background: var(--bg-color);
  border: 2px solid var(--color-substrate);
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
  overflow-y: scroll;
  max-height: 19ex;
  height: min-content;

  a {
    font-family: 'Oswald', sans-serif; 
    font-size: 16px;
    font-variation-settings: "wght" 500;
  }
}

@media only screen and (max-width: $breakpoint_tablet) {
  .mods_details_content {
    max-height: 11.5ex;

    a {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: $breakpoint_mobile) {
  .mods_details_content {

    a {
      font-size: 12px;
    }
  }
}

.line {
  margin-bottom: 10px;
}

.description {
  color: var(--color-tarnished);
}

.input {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}